<template>
  <v-row justify="center">
    <v-col cols="12" class="text-center pt-15 pb-16">
      <v-img
        width="30%"
        :src="'https://epd.directus.app/assets/1354a475-11ff-4ef9-8c94-1ce3337a59d9?format=webp'"
        :alt="t('empty_cart')"
        class="mx-auto mt-16 mb-13" />

      <h1
        class="text-center mb-8 font-weight-bold"
        :class="{ 'text-h5': inSidebar, 'text-h5 text-md-h3 text-lg-h2': !inSidebar }">
        {{ t('cart_is_empty') }}
      </h1>

      <p class="subheadline text-center mb-8">{{ t('add_container_hint') }}</p>
      <ClientOnly>
        <GlobalWasteDropdown type="cart" :error="false" @selected-waste="goToFractionPage" />
      </ClientOnly>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import type { Fraction } from '~/types/types'
const offerStore = useOfferStore()

const { t } = useI18n()

defineProps<{ fractionsSorted: Fraction[] | null; inSidebar?: boolean }>()

async function goToFractionPage(selectedFraction: Fraction) {
  offerStore.setCurrentFractionId(selectedFraction.id)
  await navigateTo({ path: `/${selectedFraction.shop_slug}` })
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.menu-on-top {
  z-index: 10000 !important; /* Adjust the z-index value as needed */
}
</style>
